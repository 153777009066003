import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import PCIcon from "./pontos/PC.ico";
import circunscricaoData from "./pontos/circunscricao_pc.json"; // Importe o arquivo JSON

const customIcon = new L.Icon({
  iconUrl: PCIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

function Map({ center, logradouro, numero, policeUnits = [] }) {
  const [polygons, setPolygons] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null); // Estado para armazenar a área selecionada

  useEffect(() => {
    if (circunscricaoData.features && circunscricaoData.features.length > 0) {
      const polygonData = circunscricaoData.features.map((feature) => {
        const { coordinates, type, properties } = feature.geometry;

        // Verifica se as coordenadas estão definidas e possui dados
        if (!coordinates || coordinates.length === 0) {
          console.error(
            "Coordenadas inválidas ou ausentes para o polígono:",
            feature
          );
          return []; // Retorna um array vazio em caso de erro
        }

        // Verifica se o tipo é MultiPolygon
        if (type === "MultiPolygon") {
          return coordinates.flatMap((multiPolygon) => {
            // Verifica se o multiPolygon e suas coordenadas estão definidos
            if (
              !multiPolygon ||
              multiPolygon.length === 0 ||
              !multiPolygon[0]
            ) {
              console.error(
                "Coordenadas do MultiPolygon inválidas:",
                multiPolygon
              );
              return []; // Retorna um array vazio em caso de erro
            }
            return {
              positions: multiPolygon[0].map(([lng, lat]) => [lat, lng]),
              properties: feature.properties, // Armazena as propriedades, incluindo o nome
            };
          });
        }

        // Verifica o tipo Polygon
        if (type === "Polygon") {
          if (!coordinates[0] || coordinates[0].length === 0) {
            console.error("Coordenadas do Polygon inválidas:", coordinates);
            return []; // Retorna um array vazio em caso de erro
          }
          return {
            positions: coordinates[0].map(([lng, lat]) => [lat, lng]),
            properties: feature.properties, // Armazena as propriedades
          };
        }

        return [];
      });

      setPolygons(polygonData.flat()); // Remove níveis extras de aninhamento
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Forçar recarga do mapa para garantir que os tiles sejam carregados corretamente
      window.L.DomEvent.on(window, "load", () => {
        window.L.map("map").invalidateSize();
      });
    }
  }, []);

  const handlePolygonClick = (properties) => {
    // Define a área selecionada no estado
    setSelectedArea(properties);
  };

  const RedrawMap = ({ center }) => {
    const map = useMap();
    useEffect(() => {
      map.invalidateSize();
      map.setView(center);
    }, [center, map]);
    return null;
  };

  return (
    <MapContainer
      center={center}
      zoom={16}
      style={{ height: "500px", width: "100%" }}
      interactive={true}
      attributionControl={false}
    >
      <RedrawMap center={center} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <Marker position={center}>
        <Popup>
          <p>{`Endereço: ${logradouro}, Nº: ${numero}`}</p>
        </Popup>
      </Marker>

      {policeUnits.map((unit, index) => (
        <Marker
          key={index}
          position={[unit.latitude, unit.longitude]}
          icon={customIcon}
        >
          <Popup>{unit.name}</Popup>
        </Marker>
      ))}
      {polygons.map((polygon, index) => (
        <Polygon
          key={index}
          positions={polygon.positions}
          color="blue"
          eventHandlers={{
            click: () => handlePolygonClick(polygon.properties), // Adiciona o clique no polígono
          }}
        >
          {selectedArea && selectedArea === polygon.properties && (
            <Popup>
              <div>
                <h3>{selectedArea.Name}</h3>
                <p>{`Bairro: ${selectedArea.BAIRRO}`}</p>
                <p>{`Assistente: ${selectedArea.ASSISTENTE}`}</p>
                <p>{`Telefone: ${selectedArea.TITULAR}`}</p>
              </div>
            </Popup>
          )}
        </Polygon>
      ))}
    </MapContainer>
  );
}

export default Map;
