import React from "react";
import { AppContext } from "../../../context";
import { Container, Tab, Tabs } from "react-bootstrap";
import DashBoard from "./graficos/DashBoard";
import ScoreCard from "./graficos/ScoreCard";

class DisqueDenunciaPage extends React.Component {
  static contextType = AppContext;

  render() {
    const { isAuthorized } = this.context;

    // Verifica se o usuário tem permissão para ver a DashBoard
    const podeVerDashBoard = isAuthorized("disquedenuncia_dashboard");

    const podeVerScorecard = isAuthorized("disquedenuncia_scorecard");

    return (
      <Container fluid>
        <h3>Estatísticas e DashBoard</h3>

        <Tabs defaultActiveKey={podeVerScorecard? "estatistica": "dashBoard"} id="contol_tab">
          {podeVerScorecard && (
            <Tab title="Estatísticas" eventKey="estatisticas">
              <ScoreCard />
            </Tab>)}
          {podeVerDashBoard && (
            <Tab title="DashBoard" eventKey="dashBoard">
              <DashBoard />
            </Tab>
          )}
        </Tabs>
      </Container>
    );
  }
}

export default DisqueDenunciaPage;
