import React from "react";
import { AppContext } from "../../../../context";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Request from "../../../../request";
import { Bar, PolarArea } from "react-chartjs-2";
import Select from "react-select";

class DashBoard extends React.Component {
  static contextType = AppContext;

  state = {
    countEncaminhada: [], // Inicialize como array vazio
    countRecebida: [],
    countEmDiligencia: [],
    countFinalizada: [],
    countRegistradaCidades: [],
    natureza: [],
    cidade:[],
    bairro:[],
    setores: [],
    orgaos: [],
    selectedOrgao: null,
    selectedSetor: null,
    dataInicial: null,
    dataFinal: null,
  };
  

  componentDidMount() {
    this.fetchData();

    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchData() {
    let url = `${this.context.config.BACKEND_URL}/denuncia/dashboard`;
    const { dataInicial, dataFinal, selectedOrgao, selectedSetor } = this.state;

    // Validação de data
    
    if (dataInicial && dataFinal) {
      if (dataInicial > dataFinal) {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Data inicial não pode ser maior que a data final.",
        });
      }
    }

    if (dataInicial && dataFinal) {
      url += `/${dataInicial}/${dataFinal}`;
    } else if (dataInicial) {
      url += `/${dataInicial}/*`;
    } else if (dataFinal) {
      url += `/*/${dataFinal}`;
    }

    if (selectedOrgao && selectedSetor) {
      if(!dataInicial && !dataFinal){
        url += `/*/*`;
      }
      url += `/${selectedOrgao}/${selectedSetor}`;
    } else if (selectedOrgao) {
      if(!dataInicial && !dataFinal){
        url += `/*/*`;
      }
      url += `/${selectedOrgao}/*`;
    }else if (selectedSetor){
      if(!dataInicial && !dataFinal){
        url += `/*/*`;
      }
      url += `/*/${selectedSetor}/`;
    }
    console.log(url)
    Request("GET", url, this.context.token)
    .query({
      orgaoId: selectedOrgao?.value || "",
      setorId: selectedSetor?.value || "",
      dataInicial: dataInicial || "",
      dataFinal: dataFinal || "",
    })
    .send()
    .then((res) => {
      console.log(res.body)
      this.setState({
        countEncaminhada: res.body.countEncaminhada,
        countRecebida: res.body.countRecebida,
        countEmDiligencia: res.body.countEmDiligencia,
        countFinalizada: res.body.countFinalizada,
        natureza:res.body.natureza,
        cidade:res.body.cidade,
        bairro:res.body.bairro,
      });
    })
      .catch((err) => {
        this.context.addToast({
          title: "Erro",
          text: "Falha na recuperação da lista de permissões: " + err.toString(),
        });
      });
}


  handleFilterButtonClick = () => {
    this.fetchData();
  };

  handleResetFilters = () => {
    this.setState(
      {
        dataInicial: null,
        dataFinal: null,
        selectedOrgao: null,
        selectedSetor: null,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleOrgaoChange = (selectedOption) => {
    this.setState({ selectedOrgao: selectedOption.value });
  };

  handleSetorChange = (selectedOption) => {
    this.setState({ selectedSetor: selectedOption.value });
  };

  render() {
    const variants = ["primary", "warning", "danger", "success", "info"];
    const randomNumberInRange = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const labelsCountRegCid = this.state.cidade?.map(
      (item) => item.Cidade?.nome
    );
    const dataCountRegCid = this.state.cidade?.map(
      (item) => item.count
    );
    const labelsCountNat = this.state.natureza?.map(
      (item) => item.Natureza?.nome
    );
    const dataCountNat = this.state.natureza.map((item) => item.count);
  
    const labelsBairro = this.state.bairro?.map(
      (item) => item.Bairro?.nome
    );
    const dataBairro = this.state.bairro?.map(
      (item) => item.count
    );

    const colors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
    ];

    const borderColor = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];

    const chartDataRegCid = {
      labels: labelsCountRegCid,
      datasets: [
        {
          label: "Total Registrada por Cidade",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) =>
            color.replace("0.2", "0.4")
          ),
          hoverBorderColor: borderColor,
          data: dataCountRegCid,
        },
      ],
    };

    const chartDataNat = {
      labels: labelsCountNat,
      datasets: [
        {
          label: "Top 10 Naturezas Mais Registradas",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) =>
            color.replace("0.2", "0.4")
          ),
          hoverBorderColor: borderColor,
          data: dataCountNat,
        },
      ],
    };
    const chartDataBairro = {
      labels: labelsBairro,
      datasets: [
        {
          label: "Top 10 Bairros Mais Registrados",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) =>
            color.replace("0.2", "0.4")
          ),
          hoverBorderColor: borderColor,
          data: dataBairro,
        },
      ],
    };


    const { setores } = this.state;
    const { orgaos } = this.state;

    return (
      <>
        <Row className="mt-2">
          <Col md={6}>
            <Form.Label>Órgão</Form.Label>
            <Select
              name="OrgaoId"
              noOptionsMessage={() => "Nada encontrado."}
              placeholder="Pesquisar..."
              menuPortalTarget={document.body}
              menuShouldBlockScroll={true}
              options={orgaos}
              value={
                orgaos.find(
                  (option) => option.value === this.state.selectedOrgao
                ) || null
              } // Define o valor para null quando resetado
              onChange={this.handleOrgaoChange}
            />
          </Col>
          <Col md={6}>
            <Form.Label>Setor</Form.Label>
            <Select
              name="SetorId"
              noOptionsMessage={() => "Nada encontrado."}
              placeholder="Pesquisar..."
              menuPortalTarget={document.body}
              menuShouldBlockScroll={true}
              options={setores.filter(
                (setor) => setor.OrgaoId === this.state.selectedOrgao
              )}
              value={
                setores.find(
                  (option) => option.value === this.state.selectedSetor
                ) || null
              } // Define o valor para null quando resetado
              onChange={this.handleSetorChange}
            />
          </Col>
          <Col className="my-2 d-flex align-items-center" md={4} lg={3}>
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span style={{ whiteSpace: "nowrap" }}>Data Inicial</span>
            <input
              type="datetime-local"
              name="dataInicial"
              className="form-control ml-2"
              value={this.state.dataInicial || ""} // Define como string vazia quando resetado
              onChange={this.handleInputChange}
            />
          </Col>
          <Col className="my-2 d-flex align-items-center" md={4} lg={3}>
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span style={{ whiteSpace: "nowrap" }}>Data Final</span>
            <input
              type="datetime-local"
              name="dataFinal"
              className="form-control ml-2"
              value={this.state.dataFinal || ""} // Define como string vazia quando resetado
              onChange={this.handleInputChange}
            />
          </Col>
          <Col
            className="w-100 my-2 d-flex justify-content-between"
            md={4}
            lg={3}
          >
            <Button
              variant="primary"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleFilterButtonClick}
            >
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Filtrar
            </Button>
            <Button
              variant="danger"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleResetFilters}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
              Limpar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
              <Card
                bg={variants[randomNumberInRange(0, 4)]}
                className="text-white mb-3"
                style={{ height: "150px" }}
              >
                <Card.Header>Encaminhadas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countEncaminhada?? 0}</h1>
                </Card.Body>
              </Card>
   
          </Col>
          <Col>
              <Card
                bg={variants[randomNumberInRange(0, 4)]}
                className="text-white mb-3"
                style={{ height: "150px" }}
              >
                <Card.Header>Recebidas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countRecebida??0}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                bg={variants[randomNumberInRange(0, 4)]}
                className="text-white mb-3"
                style={{ height: "150px" }}
              >
                <Card.Header>Em Diligencia</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countEmDiligencia??0}</h1>
                </Card.Body>
              </Card>
        
          </Col>
         
          <Col>
              <Card
                bg={variants[randomNumberInRange(0, 4)]}
                className="text-white mb-3"
                style={{ height: "150px" }}
              >
                <Card.Header>Finalizadas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countFinalizada??0}</h1>
                </Card.Body>
              </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataRegCid}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <PolarArea
              data={chartDataNat}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
          <Col className="border">
            <PolarArea
              data={chartDataBairro}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default DashBoard;